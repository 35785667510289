import React from 'react';
import Layout from '../components/layout/Layout';
import { NavProvider } from '../context/NavProvider';
import { ContentProvider } from '../context/ContentProvider';
import { ScrollProvider } from '../context/ScrollProvider';
import Helmet from 'react-helmet';
import Content from '../components/content/Content';
import loadable from '@loadable/component';

const Environment = loadable(() => import('../components/environment/Environment'));

const IndexPage = () => (
  <NavProvider>
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <script
        type="module"
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
      ></script>
      <script src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
      <title>Aleksandr Gribko Portfolio, Full stack develloper based in France</title>
      <meta
        content="Aleksandr Gribko portfolio. Sasha Gribko portfolio. Alexandr Gribko website and CV. Belarussian. Full stack programmer, front-end developper"
        property="og:description"
      ></meta>
      <meta
        content="Aleksandr Gribko portfolio. Sasha Gribko portfolio. Alexandr Gribko website and CV. Belarussian. Full stack programmer, front-end developper"
        property="description"
      ></meta>
      <meta
        name="description"
        content="Aleksandr Gribko portfolio. Sasha (Sacha) Gribko portfolio. Alexandr Gribko website and CV. Belarussian. Full stack programmer, front-end developper based in France"
      ></meta>
      <meta
        content="Full stack programmer, front-end developper, javascript, react, threejs portfolio"
        name="keywords"
      ></meta>
      <meta content="Aleksandr Gribko portfolio" property="og:site_name"></meta>
    </Helmet>
    <ContentProvider>
      <ScrollProvider>
        <Layout>
          <Content />
          <Environment />
        </Layout>
      </ScrollProvider>
    </ContentProvider>
  </NavProvider>
);

export default IndexPage;
