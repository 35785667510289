import React from 'react';
import * as styles from './Content.module.scss';
import About from './About/About';
import Education from './Education/Education';
import Skills from './Skills/Skills';
import Experience from './Experience/Experience';
import { Projetcs } from './Projects';
import { Languages } from './Languages';
import Contacts from './Contacts/Contacts';

function Content() {
  return (
    <section className={styles.content} id="content-wrap">
      <About />
      <Education />
      <Experience />
      <Skills />
      <Projetcs />
      <Languages />
      <Contacts />
    </section>
  );
}

export default Content;
