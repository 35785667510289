import React, { useEffect, useRef } from 'react';
import { useNav } from '../../context/NavProvider';
import * as styles from './ScrollingWrap.module.scss';
import { useScroll } from '../../context/ScrollProvider';

function ScrollingWrap({ children, contentInd }) {
  const { nav, isAnimationInProgress, functions } = useNav();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { scrollPosition } = useScroll();

  const baseRef = useRef();

  useEffect(() => {
    const scrollTrigger = document?.querySelector(`#scroll-trigger-down-${contentInd}`);

    const clientHeight = document?.documentElement.clientHeight;

    if (!isAnimationInProgress && scrollTrigger?.getBoundingClientRect()?.top < clientHeight / 2) {
      if (nav !== contentInd + 1 && scrollTrigger?.getBoundingClientRect()?.top > 0) {
        functions.setNav(contentInd + 1);
      }

      if (nav !== contentInd && scrollTrigger?.getBoundingClientRect()?.top > 50) {
        functions.setNav(contentInd);
      }
    }
  }, [isAnimationInProgress, nav, scrollPosition]);

  return (
    <div className={styles.wrap}>
      <div id={`scroll-base-${contentInd}`} className={styles.base} ref={baseRef} />
      <div className={styles.initial_focused_content}>{children}</div>
      {contentInd === 6 ? null : (
        <div id={`scroll-trigger-down-${contentInd}`} className={styles.trigger} />
      )}
      {isAnimationInProgress ? (
        // @ts-expect-error: jsx
        <style jsx="true" global="true">{`
          html {
            overflow-y: hidden;
          }
        `}</style>
      ) : null}
      {!isAnimationInProgress && contentInd === nav ? (
        // @ts-expect-error: jsx
        <style jsx="true" global="true">{`
          .section-wrap {
            animation-name: filterOpacity;
            -webkit-animation-name: filterOpacity;
            -moz-animation-name: filterOpacity;
            -o-animation-name: filterOpacity;
            animation-duration: 1s;
            filter: opacity(100%);
          }
        `}</style>
      ) : null}
    </div>
  );
}

export default ScrollingWrap;
