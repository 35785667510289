const firebase = typeof window !== `undefined` ? require('firebase/compat/app') : null;

import 'firebase/compat/firestore';
import 'isomorphic-fetch';

const firebaseConfig = {
  apiKey: process.env.GATSBY_APP_API_FIRESTORE,
  authDomain: process.env.GATSBY_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.GATSBY_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_APP_SENDER_ID,
  appId: process.env.GATSBY_APP_APP_ID,
};

firebase?.default.initializeApp(firebaseConfig);

let db;

if (!db && firebase) db = firebase.default.firestore();

export const getSkills = async () => {
  if (!db) return;
  const doc = await db.collection('skills').get();
  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });
  return res;
};

export const getExperience = async () => {
  if (!db) return;
  const doc = await db.collection('experience').get();
  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });
  return res;
};

export const getHeaders = async () => {
  if (!db) return;
  const doc = await db.collection('headers').get();
  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });
  return res;
};

export const getEducation = async () => {
  if (!db) return;
  const doc = await db.collection('education').get();
  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });
  return res;
};

export const getProjects = async () => {
  if (!db) return;
  const doc = await db.collection('projects').get();

  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });

  return res;
};

export const getLanguages = async () => {
  if (!db) return;
  const doc = await db.collection('languages').get();

  let res = [];
  doc.forEach(element => {
    res.push({ ...element.data(), id: element.id });
  });
  console.log(res);

  return res;
};
