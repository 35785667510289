import React from 'react';
import * as styles from './About.module.scss';
import { ScrollingWrap } from '../../ScrollingWrap';
import useLettersFadeApperance from '../../../hooks/useLettersFadeApperance';

function About() {
  const { lettersFadeApperance } = useLettersFadeApperance();

  return (
    <>
      <div id="top-of-doc" />
      <ScrollingWrap contentInd={0}>
        <section className={styles.about}>
          <h1>{lettersFadeApperance('Aleksandr Gribko')}</h1>
          <h2>{lettersFadeApperance('Web developer based in France')}</h2>

          <a
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/test-977eb.appspot.com/o/CV_ALEKSANDR_GRIBKO.pdf?alt=media&token=3f5f03e8-e2de-4c85-8241-e141c8975ca0"
          >
            CV <ion-icon name="document-text-outline"></ion-icon>
          </a>
        </section>
      </ScrollingWrap>
    </>
  );
}

export default About;
