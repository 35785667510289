import React, { useReducer, createContext, useContext, useMemo } from 'react';

const Scroll = createContext();

const initState = {
  scrollPosition: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SCROLL_POSITION':
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const ScrollProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const functions = useMemo(
    () => ({
      setScrollPosition: async pos => {
        // console.log(pos)
        dispatch({ type: 'SET_SCROLL_POSITION', scrollPosition: pos });
      },
    }),
    [],
  );

  return (
    <Scroll.Provider
      value={{
        scrollPosition: state.scrollPosition,
        functions,
      }}
    >
      {children}
    </Scroll.Provider>
  );
};

export const useScroll = () => useContext(Scroll);
