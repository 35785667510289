import React from 'react';
import * as styles from './LoadingOverlay.module.scss';
import { useNav } from '../../context/NavProvider';
import Navigation from '../Navigation/Navigation';

const LoadingOverlay = () => {
  const { sceneReadyPersent } = useNav();

  return (
    <div className={`${styles.loading_overlay} ${sceneReadyPersent == 100 ? styles.fade_out : ''}`}>
      <Navigation />
      {sceneReadyPersent < 100 ? (
        <div className={styles.loader}>
          <div className={styles.loader_back}>
            <div id="loader-bar" className={styles.loader_fill} />

            <span id="loader-persentage">{sceneReadyPersent}%</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LoadingOverlay;
