import React, { useReducer, createContext, useContext, useMemo, useEffect } from 'react';
import {
  getSkills,
  getEducation,
  getExperience,
  getProjects,
  getLanguages,
} from '../services/firebase';
import { useNav } from './NavProvider';

const Content = createContext();

const initState = {
  skills: null,
  education: null,
  experience: null,
  projects: null,
  languages: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SKILLS':
      return {
        ...state,
        skills: action.skills,
      };
    case 'SET_EDUCATION':
      return {
        ...state,
        education: action.education,
      };
    case 'SET_EXPERIENCE':
      return {
        ...state,
        experience: action.experience,
      };

    case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.projects,
      };
    case 'SET_LANGUAGES':
      return {
        ...state,
        languages: action.languages,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const ContentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { nav } = useNav();

  const { education, experience, skills, projects, languages } = state;

  useEffect(() => {
    !education && functions.getEducation();
    !experience && functions.getExperience();
    !skills && functions.getSkills();
    !projects && functions.getProjects();
    !languages && functions.getLanguages();
  }, [nav]);

  const functions = useMemo(
    () => ({
      getSkills: async () => {
        const res = await getSkills();

        dispatch({ type: 'SET_SKILLS', skills: res });
      },
      getEducation: async () => {
        const res = await getEducation();

        dispatch({ type: 'SET_EDUCATION', education: res });
      },
      getExperience: async () => {
        const res = await getExperience();

        dispatch({ type: 'SET_EXPERIENCE', experience: res });
      },
      getProjects: async () => {
        const res = await getProjects();

        dispatch({ type: 'SET_PROJECTS', projects: res });
      },
      getLanguages: async () => {
        const res = await getLanguages();

        dispatch({ type: 'SET_LANGUAGES', languages: res });
      },
    }),
    [],
  );

  return (
    <Content.Provider
      value={{
        skills: state.skills,
        education: state.education,
        experience: state.experience,
        projects: state.projects,
        languages: state.languages,
        functions,
      }}
    >
      {children}
    </Content.Provider>
  );
};

export const useContent = () => useContext(Content);
