import React, { useEffect } from 'react';
import '../../globalStyle/global.scss';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import * as styles from './Layout.module.scss';
import { useNav } from '../../context/NavProvider';
import { useScroll } from '../../context/ScrollProvider';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SectionTitle } from '../SectionTitle';
import { cameraTransitionTime } from '../../config';

gsap.registerPlugin(ScrollToPlugin);

function Layout({ children }) {
  const { nav, sceneReadyPersent, functions } = useNav();
  const scroll = useScroll();

  const scrollListener = () => {
    scroll.functions.setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    gsap.to(window, {
      duration: cameraTransitionTime / 1000,
      scrollTo: `#scroll-base-${nav}`,
      onComplete: () => functions.setIsAnimationInProgress(false),
    });
  }, [nav]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <div className={styles.layout}>
      {sceneReadyPersent === 100 ? <SectionTitle /> : null}
      {children}
      <LoadingOverlay />
    </div>
  );
}

export default Layout;
