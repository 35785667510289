import React from 'react';
import * as styles from './Contacts.module.scss';
import { ScrollingWrap } from '../../ScrollingWrap';
// import ArrowRight from '../../../../svg/chevron-right-solid.svg';
import FB from '../../../../svg/facebook-f-brands.svg';
import Git from '../../../../svg/github-brands.svg';
import Linkedin from '../../../../svg/linkedin-in-brands.svg';
import Twitter from '../../../../svg/twitter-brands.svg';

const Contacts = () => {
  const contacts = [
    {
      title: 'Facebook',
      link: 'https://www.facebook.com/aleksandr.gribko',
      icon: <FB />,
    },
    {
      title: 'Githab',
      link: 'https://github.com/aleksgribko',
      icon: <Git />,
    },
    {
      title: 'Twitter',
      link: 'https://twitter.com/aleks_gribko',
      icon: <Twitter />,
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/in/aleksandrgribko/',
      icon: <Linkedin />,
    },
  ];
  return (
    <ScrollingWrap contentInd={6}>
      <div className={`${styles.contacts} section-wrap`}>
        {contacts.map(c => {
          return (
            <a key={c.link} href={c.link} target="_blank">
              <div>
                {c.icon}
                <h3>{c.title}</h3>
                {/* <ArrowRight /> */}
              </div>
            </a>
          );
        })}
      </div>
    </ScrollingWrap>
  );
};

export default Contacts;
