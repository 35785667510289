import React, { useCallback } from 'react';

const useLettersFadeApperance = () => {
  const lettersFadeApperance = useCallback((str: string) => {
    if (!str.length) {
      return '';
    }
    const arr = [];
    for (const letter of str) {
      const randomDuration = Math.random() * 10000;
      arr.push(
        <span
          key={Date.now() + arr.length}
          className="fadeIn"
          style={{ animationDuration: `${randomDuration}ms`, color: 'white' }}
        >
          {letter}
        </span>,
      );
    }

    return arr;
  }, []);

  return { lettersFadeApperance };
};

export default useLettersFadeApperance;
