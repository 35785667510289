import React from 'react';
import * as styles from './Languages.module.scss';
import { useContent } from '../../../context/ContentProvider';
import { ScrollingWrap } from '../../ScrollingWrap';

const Languages = () => {
  const { languages } = useContent();

  return (
    <ScrollingWrap contentInd={5}>
      <div className={`${styles.languages} section-wrap`}>
        <div className={styles.languages__wrap}>
          <div className={styles.languages__cards}>
            {languages?.map(item => {
              return (
                <div className={styles.languages__cards__one_card} key={item.name}>
                  <div className={styles.languages__cards__one_card__texts}>
                    <h3 className={styles.languages__cards__one_card__texts__title}>{item.name}</h3>
                    <p className={styles.languages__cards__one_card__texts__text}>{item.level}</p>
                  </div>
                  <div className={styles.devider}></div>
                  <div className={styles.word}>{item.word}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ScrollingWrap>
  );
};

export default Languages;
