import React from 'react';
import * as styles from './Card.module.scss';

interface Props {
  isLeft: boolean;
  project: any;
}

const Card = ({ isLeft, project }: Props) => {
  return (
    <div className={isLeft ? styles.card_left : styles.card_right}>
      <div className={styles.content}>
        <h2>{project.title}</h2>
        <h3>{project.type}</h3>
        <p>{project.description}</p>
        <ul>
          {project.technologies?.map((item: string) => (
            <li key={Date.now() + item}>{item}</li>
          ))}
        </ul>
        {project.site ? (
          <div className={styles.link_wrap}>
            <a target="_blank" href={project.site} rel="noreferrer">
              {/* @ts-expect-error: TODO: find a way to add  ion-icon*/}
              Check out <ion-icon name="open-outline"></ion-icon>
            </a>
          </div>
        ) : null}
      </div>

      <div className={styles.img_wrap}>
        <img src={project.img_desktop ||"/coming soon.png"} alt="project" />
      </div>
    </div>
  );
};

export default Card;
