// extracted by mini-css-extract-plugin
export var languages = "Languages-module--languages--+z2pB";
export var languages__wrap = "Languages-module--languages__wrap--7TnlH";
export var languages__title = "Languages-module--languages__title--RHHD5";
export var languages__cards = "Languages-module--languages__cards--8FYmt";
export var languages__cards__one_card = "Languages-module--languages__cards__one_card--q9OOG";
export var languages__cards__one_card__img = "Languages-module--languages__cards__one_card__img--CB7hH";
export var languages__cards__one_card__texts = "Languages-module--languages__cards__one_card__texts--uuZLo";
export var languages__cards__one_card__texts__title = "Languages-module--languages__cards__one_card__texts__title--YDlns";
export var languages__cards__one_card__texts__text = "Languages-module--languages__cards__one_card__texts__text--wCIDB";
export var devider = "Languages-module--devider--FYMhl";
export var word = "Languages-module--word--ZumJw";