import React from 'react';
import * as styles from './Education.module.scss';
import { useContent } from '../../../context/ContentProvider';
import { ScrollingWrap } from '../../ScrollingWrap';

const Education = () => {
  const { education } = useContent();

  return (
    <ScrollingWrap contentInd={1}>
      <div className={`${styles.education} section-wrap`}>
        <div className={styles.education__wrap}>
          <div className={styles.education__cards}>
            {education &&
              education
                .sort((a, b) => b.order - a.order)
                .map((item, ind: number) => {
                  return (
                    <div key={item.title + ind} className={styles.education__cards__one_card}>
                      <img
                        className={styles.education__cards__one_card__img}
                        src={item.img}
                        alt="education est logo"
                      ></img>
                      <div className={styles.education__cards__one_card__texts}>
                        <h3 className={styles.education__cards__one_card__texts__title}>
                          {item.title}
                        </h3>
                        <p className={styles.education__cards__one_card__texts__text}>
                          {item.degree}
                        </p>
                        <p className={styles.education__cards__one_card__texts__text}>
                          {item.period}
                        </p>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </ScrollingWrap>
  );
};

export default Education;
