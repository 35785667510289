import React from 'react';
import { useContent } from '../../../context/ContentProvider';
import { ScrollingWrap } from '../../ScrollingWrap';
import { Card } from './Card';
import * as styles from './Projects.module.scss';

const Projects = () => {
  const { projects } = useContent();

  return (
    <ScrollingWrap contentInd={4}>
      <div className={`${styles.projects} section-wrap`}>
        <div className={styles.cards_wrap}>
          {projects &&
            projects
              .sort((a, b) => a.order - b.order)
              .map((item, ind) => <Card project={item} isLeft={ind % 2 === 0 || ind === 0} />)}
        </div>
      </div>
    </ScrollingWrap>
  );
};

export default Projects;
