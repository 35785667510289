import React, { useMemo } from 'react';
import * as styles from './SectionTitle.module.scss';
import useLettersFadeApperance from '../../hooks/useLettersFadeApperance';
import { useNav } from '../../context/NavProvider';

const SectionTitle = () => {
  const { headers, nav, isAnimationInProgress, functions } = useNav();
  const { lettersFadeApperance } = useLettersFadeApperance();

  const letters = useMemo(
    () =>
      lettersFadeApperance(
        headers && headers.length ? headers.find(h => h.order == nav).title : '',
      ),
    [lettersFadeApperance, nav],
  );

  const switchSection = (direction: 'up' | 'down' | 'first' | 'last') => {
    if (!direction || isAnimationInProgress) return;
    switch (direction) {
      case 'down':
        functions.setNav(nav + 1);
        break;
      case 'up':
        functions.setNav(nav - 1);
        break;
      case 'first':
        functions.setNav(0);
        break;
      case 'last':
        functions.setNav(6);
        break;
      default:
        break;
    }
  };

  if (nav == 0)
    return (
      <div className={styles.section_controll_buttons}>
        {/* @ts-expect-error: icon doesn't exist */}
        <ion-icon name={'phone-portrait-outline'} onClick={() => switchSection('last')} />
        {/* @ts-expect-error: icon doesn't exist */}
        <ion-icon name="chevron-down-outline" onClick={() => switchSection('down')} />
      </div>
    );

  return (
    <>
      <h2 className={styles.section_title}>{letters}</h2>
      <div className={styles.section_controll_buttons}>
        {/* @ts-expect-error: icon doesn't exist */}
        <ion-icon name={'person-outline'} onClick={() => switchSection('first')} />
        {/* @ts-expect-error: icon doesn't exist */}
        <ion-icon name={'phone-portrait-outline'} onClick={() => switchSection('last')} />
        {/* @ts-expect-error: icon doesn't exist */}
        <ion-icon name="chevron-up-outline" onClick={() => switchSection('up')} />

        {nav < 6 ? (
          // @ts-expect-error: icon doesn't exist
          <ion-icon name="chevron-down-outline" onClick={() => switchSection('down')} />
        ) : null}
      </div>
    </>
  );
};

export default SectionTitle;
