import React, { useReducer, useEffect, createContext, useContext, useMemo } from 'react';
import { getHeaders } from '../services/firebase';

interface NavContent {
  nav: number;
  isLoading: boolean;
  sceneReadyPersent: number;
  isAnimationInProgress: boolean;
  headers: [{ title: string; order: number }] | null;
  functions?: any;
}

const initState = {
  nav: 0,
  isLoading: false,
  sceneReadyPersent: 0,
  isAnimationInProgress: false,
  headers: null,
};

const Nav = createContext<NavContent>(initState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NAV':
      return {
        ...state,
        isAnimationInProgress: true,
        nav: action.nav,
      };
    case 'SET_SCENE_READY_PERCENT':
      return {
        ...state,
        sceneReadyPersent: action.sceneReadyPersent,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case 'SET_ANIMATION_IN_PROGRESS':
      return {
        ...state,
        isAnimationInProgress: action.isAnimationInProgress,
      };
    case 'SET_HEADERS':
      return {
        ...state,
        headers: action.headers,
      };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const NavProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  useEffect(() => {
    const handleGetHeaders = async () => {
      const res = await getHeaders();

      if (res) dispatch({ type: 'SET_HEADERS', headers: res });
    };
    !state.headers && handleGetHeaders();
  }, []);

  const functions = useMemo(
    () => ({
      setLoader: value => dispatch({ type: 'SET_LOADER', isLoading: value }),
      setSceneReadyPercent: value => {
        dispatch({ type: 'SET_SCENE_READY_PERCENT', sceneReadyPersent: value });
      },
      setNav: async (nav: number) => {
        const clearedNumber = Math.max(0, Math.min(6, nav));
        if (clearedNumber != state.nav) dispatch({ type: 'SET_NAV', nav: clearedNumber });
      },
      setIsAnimationInProgress: async data =>
        dispatch({ type: 'SET_ANIMATION_IN_PROGRESS', isAnimationInProgress: data }),
    }),
    [state.nav],
  );

  const sateToReturn = {
    nav: state.nav,
    isLoading: state.isLoading,
    sceneReadyPersent: state.sceneReadyPersent,
    isAnimationInProgress: state.isAnimationInProgress,
    headers: state.headers,
    functions,
  };

  return <Nav.Provider value={sateToReturn}>{children}</Nav.Provider>;
};

export const useNav = () => useContext(Nav);
