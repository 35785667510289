import React, { useEffect } from 'react';
import * as styles from './Skills.module.scss';
import { useContent } from '../../../context/ContentProvider';

import { Chart, registerables } from 'chart.js';
import { ScrollingWrap } from '../../ScrollingWrap';
Chart.register(...registerables);

const Skills = () => {
  useEffect(() => {
    const ctx = document.getElementById('myChart');
    // @ts-expect-error: ctx
    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: [
          'Web dev',
          'Back-end',
          'Data Bases',
          'Project management',
          'Native dev',
          'Testing',
          'Design',
          '3D',
        ],
        datasets: [
          {
            label: 'Experience',
            data: [85, 30, 40, 65, 60, 40, 45, 60],
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: 'white',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Interests',
            data: [90, 45, 45, 60, 50, 40, 80, 90],
            fill: true,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: 'white',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)',
          },
        ],
      },
      options: {
        elements: {
          line: {
            borderWidth: 3,
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      },
    });
  }, []);

  const { skills } = useContent();

  return (
    <ScrollingWrap contentInd={3}>
      <div className={`${styles.skills} section-wrap`}>
        <div className={styles.skills__wrap}>
          <div className={styles.skills__radar_wrap}>
            <canvas id="myChart" width="400" height="400"></canvas>
          </div>
          <div>
            {skills?.map(item => {
              return (
                <div key={item.id}>
                  <h2>{item.title}</h2>
                  <div className={styles.card_line}>
                    {item.tools.map((tool: string, ind: number) => {
                      return (
                        <span key={item.id + ind} className={styles.card_line__item}>
                          {tool}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ScrollingWrap>
  );
};

export default Skills;
