import React from 'react';
import * as styles from './Navigation.module.scss';
// import Scroll from './Scroll';
import { useNav } from '../../context/NavProvider';

const Navigation = () => {
  const { nav, isAnimationInProgress } = useNav();

  if (isAnimationInProgress) return <></>;

  return (
    <div className={nav === 0 ? styles.navigation_absolute : styles.navigation}>
      {/* <div className={styles.inline}>
        <div className={styles.grabbing_wrap}>
          <div className={styles.grabbing} />
        </div>
        <span>Drag to observe</span>
      </div> */}
      <div className={styles.inline}>
        {/* <Scroll /> */}
        {/* <span>Scroll to zoom</span> */}
      </div>

      {/* <div className={styles.development}>
        <img
          src="https://img.icons8.com/color/48/000000/under-construction.png"
          alt="site under development"
        />
        This site is under development
      </div> */}
    </div>
  );
};

export default Navigation;
