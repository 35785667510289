import React, { useState } from 'react';
import { useContent } from '../../../context/ContentProvider';
import * as styles from './Experience.module.scss';
import { ScrollingWrap } from '../../ScrollingWrap';

const Experience = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { experience } = useContent();

  if (!experience) return <></>;

  return (
    <ScrollingWrap contentInd={2}>
      <div className={`${styles.experience} section-wrap`}>
        <div className={styles.experience__tabs}>
          <button
            onClick={() => setSelectedTab(0)}
            onKeyUp={() => setSelectedTab(0)}
            className={
              selectedTab === 0 ? styles.experience__tabs__button : styles.not_active_button
            }
          >
            Web development
          </button>
          <button
            onClick={() => setSelectedTab(1)}
            onKeyUp={() => setSelectedTab(1)}
            className={
              selectedTab === 1 ? styles.experience__tabs__button : styles.not_active_button
            }
          >
            Legal
          </button>
        </div>
        <div className={styles.experience__content}>
          {experience
            .filter(item => {
              return selectedTab === 0 ? item.type === 'tech' : item.type === 'legal';
            })
            .sort((a, b) => b.order - a.order)
            .map((item, ind) => {
              return (
                <div key={item.title + ind} className={styles.card}>
                  <h2>{item.company}</h2>
                  <h3>
                    {item.title}, {item.period}
                  </h3>
                  <ul>
                    {item.description.map((item, ind: number) => (
                      <li key={item + ind}>{item}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </ScrollingWrap>
  );
};

export default Experience;
